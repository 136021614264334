import dayjs, { Dayjs } from "dayjs";
import { randomName } from "mocks/data/utils";
import { DateNumber, DateString } from "types/global";


/** millisecondi presenti in un giorno */
export const DAY_MILLISEC = 1000 * 60 * 60 * 24

/** 
 * il parametro puo' essere un "getTime" oppure una DateString
 * lo restituisce con hours, minutes, seconds millisec a 0 
 */
export function dateWithoutTime(date: DateString | number): DateNumber {
	if (!date) return null
	const ddate = new Date(date)
	ddate.setUTCHours(0, 0, 0, 0)
	const ndate = ddate.getTime()
	return isNaN(ndate) ? null : ndate
}

export function dateWithoutTime2(date: DateString | number | Dayjs): DateNumber {
	if (!date) return null
	return dayjs(date).startOf("day").valueOf()
}

/** 
  * restituisce la data corrente con hours, minutes, seconds millisec a 0
 */
export function nowWithoutTime(): DateNumber {
	const date = new Date();
	date.setUTCHours(0, 0, 0, 0);
	return date.getTime();
}

/**
 * restituisce il numero di giorni tra time1 e time2  
 * il numero puo' essere negativo
 */
export function diffDay(time1: number, time2: number): number {
	return Math.ceil((time1 - time2) / DAY_MILLISEC)
}

/**
 * arrotonda il valore sui minuti
 **/
export function roundMinute(value: number): string {
	const minutes = Math.round(value)
	if (minutes < 60) return `${minutes}m`
	const hours = value / 60
	if (hours < 10) return `${hours.toFixed(1)}h`
	if (hours < 24) return `${Math.round(hours)}h`
	const days = hours / 24
	if (days < 10) return `${days.toFixed(1)}h`
	return `${Math.round(days)}g`
}

/**
 * Restituisce `true` se la data inserita è oggi
 * @param date un valore string o numerico che vada bene in `new Date(<date>)`
 * @returns 
 */
export function isToday(date: any): boolean {
	const otherDate = new Date(date)
	const today = new Date()
	return otherDate.getFullYear() === today.getFullYear() &&
		otherDate.getMonth() === today.getMonth() &&
		otherDate.getDate() === today.getDate();
}

/**
 * Mi dice se tra date1 e date2 c'e' un cambio di giorno
 */
export function isDayChanged(date1: Date | number | string, date2: Date | number | string): boolean {
	if (!date1 || !date2) return false
	const time1 = new Date(date1)
	const time2 = new Date(date2)
	return time1.getFullYear() != time2.getFullYear()
		|| time1.getMonth() != time2.getMonth()
		|| time1.getDate() != time2.getDate()
}







let timeoutIDs = {};
/**
 * attende un determinato tempo prima di eseguire una funzione
 * se la funzione è richiamata resetta il tempo e riaspetta
 * usata per resettare un valore che rimane in loading per troppo tempo
 */
export function debounce(name: string, callback?: () => void, delay?: number): string {
	if (name == null) name = randomName(10)
	const toId = timeoutIDs[name]
	if (toId != null) clearTimeout(toId)

	if (delay == 0) {
		callback?.apply(this, null);
	} else {
		if (!callback) {
			delete timeoutIDs[name]
		} else {
			timeoutIDs[name] = setTimeout(() => {
				delete timeoutIDs[name];
				callback.apply(this, null);
			}, delay);
		}
	}

	return name
}

/**
 * crea una pausa async
 */
export function delay(millisec: number): Promise<void> {
	return new Promise(res => setTimeout(res, millisec));
}

/** gestisce l'errore di timezone */
export function applyTimezone(date: Dayjs, timezone?: string): Dayjs {
	if (!timezone) return date.utc()
	try {
		return date.tz(timezone)
	} catch (e) {
		return date.utc()
	}
}