import { StoreCore, createStore } from "@priolo/jon"
import i18n from "i18next"

/**
 * Gestione della dialog
 * Questa puo' essere modale o snackbar
 */

// used when dialog closed
let resolveClose = null

export enum DIALOG_TYPE {
	INFO = "info",
	WARNING = "warning",
	ERROR = "error",
	SUCCESS = "success",
}

/**
 * una struttura che indica come deve essere visualizzata la dialog
 */
export interface DialogMsg {
	title?: string
	text?: string
	labelOk?: string
	labelCancel?: string
	labelConfirm?: string
	checkConfirm?: boolean | null
	modal?: boolean
	type?: DIALOG_TYPE
}

const setup = {

	state: {
		dialogIsOpen: false,
		dialogTitle: "",
		dialogText: "",
		dialogLabelOk: "",
		dialogLabelCancel: <string>null,
		dialogLabelConfirm: <string>null,
		dialogCheckConfirm: <boolean>null,
		dialogModal: true, // false: Snackbar; true: Dialog
		dialogType: DIALOG_TYPE.INFO,
	},

	getters: {
	},

	actions: {
		/**
		 * Apre la dialog, restituisce un Promise che viene risolto su "dialogClose"
		 * @param conf una struttura che indica come deve essere visualizzata la dialog
		 */
		dialogOpen: (conf: DialogMsg, store?: DialogStore) => {
			store.setDialogOpen(conf)
			return new Promise((resolve, reject) => {
				resolveClose = resolve
			})
		},
		/**
		 * chiude la dialog
		 * @param payload un eventuale valore da restituire (dialogOpen risolve un Promise con questo valore)
		 */
		dialogClose: (payload?: any, store?: DialogStore) => {
			store.setDialogIsOpen(false)
			if (resolveClose) resolveClose(payload)
			resolveClose = null;
			store._update()
		}
	},

	mutators: {
		setDialogIsOpen: (dialogIsOpen: boolean) => ({ dialogIsOpen }),
		setDialogOpen: (payload: DialogMsg) => {
			const typeDefault = payload.type ?? DIALOG_TYPE.INFO
			const {
				title = i18n.t(`dialog.default.${typeDefault}.title`),
				text = "",
				labelOk = i18n.t(`dialog.default.${typeDefault}.btt-ok`, "Ok"),
				labelCancel = i18n.t(`dialog.default.${typeDefault}.btt-cancel`, ""),
				labelConfirm = null,
				checkConfirm = false,
				modal = true,
				type = typeDefault
			} = payload
			return {
				dialogTitle: title,
				dialogText: text,
				dialogIsOpen: true,
				dialogLabelOk: labelOk,
				dialogLabelCancel: labelCancel,
				dialogLabelConfirm: labelConfirm,
				dialogCheckConfirm: checkConfirm,
				dialogModal: modal,
				dialogType: type
			}
		},
		setCheckConfirm: (dialogCheckConfirm: boolean) => ({ dialogCheckConfirm }),
	},
}



export type DialogState = typeof setup.state
export type DialogGetters = typeof setup.getters
export type DialogActions = typeof setup.actions
export type DialogMutators = typeof setup.mutators
export interface DialogStore extends StoreCore<DialogState>, DialogGetters, DialogActions, DialogMutators {
	state: DialogState
}
const dialogSo = createStore(setup) as DialogStore
export default dialogSo
