import { Box, IconButton, SxProps, Tooltip } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router";



interface Props {
	href?: any
	tooltip?: string
	onClick?: (e: React.MouseEvent) => void
	sx?: SxProps
	sxContainer?: SxProps
	color?: any
	disabled?: boolean
	children?: React.ReactNode
	onMouseMove?: (e: React.MouseEvent<any>) => void
}

const ButtonIcon: FunctionComponent<Props> = ({
	href,
	tooltip = "",
	onClick,
	sx,
	sxContainer,
	color,
	disabled,
	children,
	onMouseMove,
}) => {

	// HOOKs
	const history = useHistory()

	// HANDLERs
	const handleClick = (e) => {
		e.stopPropagation()
		if (href) {
			history.push(href)
		} else {
			onClick(e)
		}
	}

	// RENDER
	return <Tooltip title={tooltip} placement="top">
		<Box sx={sxContainer}
			onMouseMove={onMouseMove}
		>
			<IconButton sx={sx}
				color={color}
				onClick={handleClick}
				size="medium"
				disabled={disabled}
			>
				{children}
			</IconButton>
		</Box>
	</Tooltip>
}

export default ButtonIcon
