import { StoreCore, createStore, mixStores, validateAll } from "@priolo/jon"
import cropsApi from "api/crops"
import i18n from "i18next"
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore"
import cropDaySo from "stores/library/crop/detail"
import { Crop } from "types/Crop"
import { Uuid } from "types/global"
import { rules } from "utils/rules"
import folderSetup from "../folder"
import { daysToPhasesGroup, phasesGroupToDays } from "./utils"



/**
 * Gestisce la lista dei CROP
 */
const setup = {

	state: {
		all: <Crop[]>[],
		// CROP in editazione
		select: <Crop>null,
		/** 
		 * i custom paramenter in editazione. 
		 * devo fare un array perche' un dictionary è difficilmente gestibile come lista editabile
		 * */
		selectCustomFields: <[string, string][]>null
	},

	getters: {
		getByUuid: (uuid: Uuid, store?: CropStore): Crop => uuid ? store.state.all.find(c => c.uuid == uuid) : null,
		getById: (id: number, store?: CropStore): Crop => store.state.all.find(c => c.id == id),
		isSelected: (id: number, store?: CropStore): boolean => {
			if (id == null) return false
			return store.state.selectedIds.includes(id)
		},
	},

	actions: {
		/** carico tutti i CROPS */
		fetchAll: async (_: void, store?: CropStore) => {
			const { data } = await cropsApi.index()
			store.setAll(data)
		},
		fetchAllIfVoid: async (_: void, store?: CropStore) => {
			if (store.state.all?.length > 0) return
			await store.fetchAll()
		},
		fetchSelect: async (id: number, store?: CropStore) => {
			const { data } = await cropsApi.get(id)
			const groupsDays = daysToPhasesGroup(data)
			store.setSelect(data)
			cropDaySo.setDaysGroups(groupsDays)
		},
		fetchSelectAndClone: async (id: number, store?: CropStore) => {
			const { data } = await cropsApi.get(id)
			delete data.id
			const groupsDays = daysToPhasesGroup(data)
			store.setSelect(data)
			cropDaySo.setDaysGroups(groupsDays)
		},
		save: async (crop: Crop, store?: CropStore) => {
			// rivalorizzo i custom fields del CROP
			crop.customFields = store.state.selectCustomFields.reduce((acc, [key, value]) => {
				if (key.trim().length == 0) return acc
				acc[key] = value;
				return acc;
			}, {} as { [key: string]: string })
			// salvataggio sul BE
			const { data } = await cropsApi.save(crop.id, crop)
			store.state.selectCustomFields = null
			store.setSelect(data)
		},
		destroy: async (id: number, store?: CropStore) => {
			const res = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: "Are you sure you want to delete the CROP?",
			})
			if (!res) return
			await cropsApi.destroy(id)
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: "THE CROP HAS BEEN DELETED",
			})
			const crops = store.state.all.filter(i => i.id != id)
			store.setAll(crops)
		},
		archive: async (id: number, store?: CropStore) => {
			const res = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: i18n.t("dialog.crop.archive.confirm"),
			})
			if (res == false) return

			const { data: cropArchived } = await cropsApi.archive(id)
			const crop = store.state.all.find(crop => crop.id == cropArchived.id)
			crop.archived = true
			store.setAll([...store.state.all])

			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t("snackbar.crop.archive"),
			})
		},
		bulkArchive: async (_: void, store?: CropStore) => {
			if (store.state.selectedIds.length == 0) return
			if (await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: i18n.t("dialog.crop.bulk-archive.confirm"),
			}) == false) return

			await cropsApi.bulkArchive(store.state.selectedIds)
			for (const crop of store.state.all) if (store.state.selectedIds.includes(crop.id)) crop.archived = true
			store.setAll([...store.state.all])
			store.setSelectedIds([])

			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t("snackbar.crop.bulk-archive"),
			})
			
		},
		restore: async (id: number, store?: CropStore) => {
			const { data: cropArchived } = await cropsApi.restore(id)
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t("snackbar.crop.restore"),
			})
			const crop = store.state.all.find(crop => crop.id == cropArchived.id)
			crop.archived = false
			store.setAll([...store.state.all])
		},


		// crea un nuovo crop e lo mette in editazione (select)
		editNew: async (_: void, store?: CropStore) => {
			store.setSelect({
				groupingFolderId: store.state.selectedFolderId,
				name: "", farmIds: [], variety: "", vendor: "",
				seedDensity: 0, yieldPerMq: 0, cost1kg: 0,
				seedCostPerMillion: 0, pillsTypology: "", seedsPillsPerHdTray: 0,
				rearrangementSchema: [], days: [],
			})
			cropDaySo.setNewDaysGroups()
		},
		// salva il CROP in editazione
		saveSelect: async (_: void, store?: CropStore) => {

			// validazione dei dati inseriti
			if (validateAll().length > 0) return
			if (rules.obligatory(store.state.select.name) || rules.obligatoryArray(store.state.select.farmIds)) return false

			// controllo che il ratio sia corretto
			if (!cropDaySo.checkAllRatio()) {
				dialogSo.dialogOpen({
					type: DIALOG_TYPE.WARNING, labelCancel: null,
					text: i18n.t("dialog.crop.ratio.text")
				})
				return false
			}

			// normalizzazione e salvataggio
			const { days, rearrangementSchema } = phasesGroupToDays(cropDaySo.state.daysGroups)
			await store.save({ ...store.state.select, days, rearrangementSchema })

			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t(`snackbar.crop.${store.state.select.id ? "update" : "create"}`)
			})
			return true
		},
	},

	mutators: {
		setAll: (all: Crop[]) => ({ all }),
		setSelect: (select: Crop) => ({ select, selectCustomFields: Object.entries(select?.customFields ?? {}) }),
		setSelectCustomFields: (selectCustomFields: [string, string][]) => ({ selectCustomFields }),
		setSelectProp: (prop: Partial<Crop>, store?: CropStore) => ({ select: { ...store.state.select, ...prop } }),
	},

}

export type CropState = typeof setup.state & typeof folderSetup.state
export type CropGetters = typeof setup.getters & typeof folderSetup.getters
export type CropActions = typeof setup.actions & typeof folderSetup.actions
export type CropMutators = typeof setup.mutators & typeof folderSetup.mutators
export interface CropStore extends StoreCore<CropState>, CropGetters, CropActions, CropMutators {
	state: CropState
}
const cropSo = createStore(mixStores(folderSetup, setup)) as CropStore
cropSo.state.repo = "crops"

export default cropSo

