import { WaterDrop as ActionIcon, Colorize as AdjIcon, Agriculture, Feedback as AlarmIcon, Report as AlertIcon, ShowChart as ChartIcon, Co2, Monitor as ConsoleIcon, Dashboard as DashboardIcon, Grain, Extension as GrowUnitIcon, Home, Hvac, Layers, Map, MonitorHeart, NotificationImportant, OfflineBolt, BlurLinear as PlannerIcon, PropaneTank, LocalOffer as PropsIcon, QueryStats, AutoStories as RecipeIcon, Schema, Sell, SettingsApplicationsRounded, Star as StarIcon, Assignment as TaskIcon, WbIncandescent } from "@mui/icons-material"
import ProductionIcon from "images/ai"
import CycleIcon from "images/cycle"
import BiIcon from "images/graph_money"
import IrrigationIcon from "images/IrrigationOn"
import CropIcon from "images/menu/crop"
import FarmIcon from "images/menu/farm"
import ReportIcon from "images/menu/kw"
import ProductIcon from "images/menu/product"
import RootIcon from "images/menu/root"
import SeedIcon from "images/seed"
import TowerIcon from "images/tower"
import React, { FunctionComponent } from "react"
import { PAGE_ID } from "stores/route/utils/types"



interface Props {
	pageId: PAGE_ID,
	sx?: any,
	onClick?: (e: React.MouseEvent) => void
}

const PageIcon: FunctionComponent<Props> = ({
	pageId,
	sx,
	onClick
}) => {
	return {
		[PAGE_ID.FARMS]: <FarmIcon style={sx} onClick={onClick} />,

		[PAGE_ID.FARM_CHARTS]: <ChartIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_CONSOLES]: <ConsoleIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_PLANNER]: <PlannerIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_CYCLES]: <CycleIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_BI]: <Agriculture sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_MAP]: <Map sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_TASKS]: <TaskIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_AI_PLANNING]: <ProductionIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_ALERTS]: <AlertIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_PLC_ALERTS]: <NotificationImportant sx={sx} onClick={onClick} />,		
		[PAGE_ID.FARM_CO2]: <PropaneTank sx={sx} onClick={onClick} />,		

		[PAGE_ID.FARM_TECHNICAL_DASHBOARDS]: <QueryStats sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_BUSINESS_DASHBOARDS]: <BiIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_EXTERNAL_LINK]: <DashboardIcon sx={sx} onClick={onClick} />,

		[PAGE_ID.FARM_GROWUNITS]: <GrowUnitIcon style={sx} onClick={onClick} />,

		[PAGE_ID.GROWUNIT_DETAIL]: <StarIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_RECIPE]: <RecipeIcon style={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_TOWERS]: <TowerIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_PROPERTIES]: <PropsIcon sx={sx} onClick={onClick} />,

		[PAGE_ID.GROWUNIT_HOME]: <Home sx={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_MAIN]: <Schema sx={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_LAYERS]: <Layers sx={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_FERTI]: <ActionIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_ADJ]: <AdjIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_LIGHTING]: <WbIncandescent sx={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_LIGHTING_PS]: <OfflineBolt sx={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_IRRIGATION]: <IrrigationIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.GROWUNIT_PLC_ALERTS]: <NotificationImportant sx={sx} onClick={onClick} />,		
		[PAGE_ID.COMPONENT_OSMOTIC_WATER]: <Grain sx={sx} onClick={onClick} />,		
		[PAGE_ID.COMPONENT_OSMOTIC_WATER_ALERTS]: <NotificationImportant sx={sx} onClick={onClick} />,		
		[PAGE_ID.COMPONENT_HVAC]: <Hvac sx={sx} onClick={onClick} />,
		[PAGE_ID.COMPONENT_HVAC_ALERTS]: <NotificationImportant sx={sx} onClick={onClick} />,
		[PAGE_ID.COMPONENT_GERMINATION]: <SeedIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.COMPONENT_GERMINATION_ALERTS]: <NotificationImportant sx={sx} onClick={onClick} />,
		[PAGE_ID.COMPONENT_POWER_MONITOR]: <MonitorHeart sx={sx} onClick={onClick} />,

		[PAGE_ID.FARM_MODULES]: <SettingsApplicationsRounded sx={sx} onClick={onClick} />,
		[PAGE_ID.FARM_METADATA]: <Sell sx={sx} onClick={onClick} />,



		[PAGE_ID.RECIPES]: <RecipeIcon style={sx} onClick={onClick} />,
		[PAGE_ID.CROPS]: <CropIcon style={sx} onClick={onClick} />,
		[PAGE_ID.PRODUCTS]: <ProductIcon style={sx} onClick={onClick} />,
		[PAGE_ID.ALARMS]: <AlarmIcon sx={sx} onClick={onClick} />,
		[PAGE_ID.ROOT]: <RootIcon style={sx} onClick={onClick} />,
		[PAGE_ID.ZW]: <ReportIcon style={sx} onClick={onClick} />,

	}[pageId as string] ?? null
}

export default PageIcon