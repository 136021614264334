const Root2Icon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor" 
		{...props}
	>
		<path d="M1.14043 18.3055C1.08649 18.9708 1.28686 19.6362 1.6799 20.133C2.07294 20.6209 2.62782 20.9048 3.19811 20.9048H20.7847C21.3627 20.9048 21.9176 20.6209 22.3107 20.133C22.7037 19.6362 22.8964 18.9708 22.8501 18.3055C22.7499 16.957 22.5342 15.6973 22.2413 14.5263C23.2046 11.8383 22.9889 10.4011 22.958 10.2414C22.9503 10.1882 22.9272 10.1261 22.9041 10.0728C22.2567 8.60907 21.4706 7.24288 20.5612 6.01863C20.5227 5.96541 20.4842 5.92105 20.4302 5.88556C20.3686 5.84121 18.9814 4.85649 17.0008 5.3799C15.1897 3.74756 13.3324 2.90479 11.9991 2.90479C10.6582 2.90479 8.80086 3.74756 6.9975 5.3799C5.01689 4.85649 3.62198 5.84121 3.56033 5.88556C3.51409 5.92105 3.47555 5.96541 3.43702 6.00976C2.52763 7.24288 1.74155 8.6002 1.09419 10.064C1.06337 10.1172 1.04795 10.1793 1.04025 10.2325C1.00942 10.3922 0.801339 11.8294 1.75697 14.5174C1.46411 15.6973 1.24833 16.957 1.14043 18.3055ZM21.6864 18.4208C21.7095 18.7224 21.6248 19.0063 21.4475 19.2281C21.278 19.441 21.0391 19.5652 20.7847 19.5652H3.20582C2.9515 19.5652 2.71259 19.4499 2.54305 19.2281C2.36579 19.0063 2.28102 18.7224 2.30414 18.4208C3.00545 9.24781 9.07831 4.24436 11.9991 4.24436C14.92 4.24436 20.9851 9.24781 21.6864 18.4208Z" />
		<path fillRule="evenodd" clipRule="evenodd" d="M6.91368 17.4558C8.05979 17.7641 9.7003 17.7641 12.0225 17.7641H12.0225H12.0225C14.2848 17.7641 15.9253 17.7641 17.0714 17.4558C18.4497 17.0809 19.1164 16.2143 19.1164 14.7979C19.1164 12.0483 16.7343 7.12402 11.9925 7.12402C7.25077 7.12402 4.86865 12.04 4.86865 14.7979C4.87614 16.2143 5.54284 17.0809 6.91368 17.4558ZM13.6037 14.9645C13.8734 15.1395 14.218 15.0395 14.3753 14.7396C14.6449 14.2313 15.2892 14.2313 15.5513 14.7396C15.6562 14.9395 15.8435 15.0479 16.0383 15.0479C16.1356 15.0479 16.233 15.0229 16.3229 14.9645C16.5926 14.7896 16.6825 14.4063 16.5252 14.1063C16.1881 13.4648 15.6038 13.0815 14.9596 13.0815C14.3153 13.0815 13.731 13.4648 13.394 14.1063C13.2441 14.398 13.334 14.7896 13.6037 14.9645ZM8.4276 14.8228C8.27029 15.1228 7.92571 15.2228 7.65603 15.0478C7.38636 14.8728 7.30396 14.4812 7.44629 14.1896C7.78338 13.548 8.37516 13.1647 9.0119 13.1647C9.65612 13.1647 10.2404 13.548 10.5775 14.1896C10.7348 14.4895 10.6449 14.8728 10.3752 15.0478C10.2854 15.1061 10.188 15.1311 10.0906 15.1311C9.89583 15.1311 9.70855 15.0228 9.60368 14.8228C9.3415 14.3229 8.69728 14.3229 8.4276 14.8228Z" />
	</svg>
)

export default Root2Icon